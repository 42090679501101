// ПЕРЕМЕННЫЕ
// --------------------------------------------------

// 1. ЦВЕТА
// --------------------------

@primary-color:           #00384e;
@primary-color-2:         #fab908;

// gray scale
// основной серый цвет, цвет контролов
@gray:                    #f6f6f6;
// более темный цвет, для рамок контролов
@gray--darken:            #adadad;



//== Параметры сетки
//##
@screen-sm:                  768px;
@screen-md:                  1024px;
@screen-lg:                  1200px;

//** Отступ между колонок. Значение делится на половину слева и справа
@grid-gutter-width:         20px;



//== MASTER Z-INDEX
//
//## настройки z-index всех значимых блоков находятся здесь для наглядного редактирования
//##
@z-index-header-modal-form:                 3000;
@z-index-header--fixed:                     2000;
@footer-z-index:                            1900;



/**
* 3. ШРИФТЫ
* -----------------------------------------------------------------------------
*/
@main-font:                "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
@mono-font:                Menlo, Monaco, Consolas, "Courier New", monospace;

.rouble {
	font-family:'rouble';
	font-weight: normal;
	font-style: normal;
}


// Font sizes
// --------------------------
@font-size: 16px;
@line-height: 1.5;




/**
* 4. ТИПОГРАФИКА
* -----------------------------------------------------------------------------
*/
@text-color:                   #373737; // цвет текстов по умолчанию

//== ОСНОВНОЙ текст, тег <p>
//
// ##
@paragraph-margin-top:         0;
@paragraph-margin-bottom:      20px;
@paragraph-lead-font-size:     @font-size * 1.5;

//== ЗАГОЛОВКИ <h1 - h6>
//
//## Основные стили заголовков
//##
@heading-color:             #222; // цвета заголовков

@heading-margin-top:        0;
@heading-margin-bottom:     35px;

@heading-h1-font-size:      40px; // размеры заголовков
@heading-h2-font-size:      34px;
@heading-h3-font-size:      28px;
@heading-h4-font-size:      20px;
@heading-h5-font-size:      16px;
@heading-h6-font-size:      14px;

@heading-font-weight:       400; // жирность шрифта заголовков



//== ССЫЛКИ
//
//##
//##
@link-color:               #0082dd;
@link-hover:               #0082dd;
@link-active:              #0082dd;
@link-visited:             #0082dd;

@link-font-size:           16px;
@link-font-weight:         400;
@link-text-decoration:     none; // "underline", "none"



//== СПИСКИ
//
//##
//#

@list-margin-bottom:                   @paragraph-margin-bottom;

//# unordered list
@unordered-list-margin-left:           0;
@unordered-list-padding-left:          0;
//# unordered item
@unordered-item-padding-left:          28px;
@unordered-list-line-height:           24px;
@unordered-list-item-marker-top:       (@unordered-list-line-height / 2) - (@unordered-list-item-marker-height / 2);
@unordered-list-item-marker-width:     10px;
@unordered-list-item-marker-height:    10px;
@unordered-list-item-border-radius:    0;

@unordered-list-marker-color:          @primary-color-2;


//# ordered list
@ordered-list-padding-left:            4px;
//# ordered item
@ordered-list-item-padding-left:       28px; // место под маркер списка
@ordered-list-item-marker-color:       @primary-color;
@ordered-item-marker-font-size:        18px;
@ordered-item-line-height:             24px;
@ordered-item-markger-font-weight:     700;



//== таблицы
//
//##
//##
@table-border-spacing:                 2px 2px; // 2 значения по оси x, y
@table-margin-bottom:                  50px;
@table-striped-background-color:       #f5f5f5; // цвета фона полос таблицы
@table-tr-hover-background-color:      #f5f5f5;

//# размеры ячеек таблицы
@table-cell-padding-top:               6px;
@table-cell-padding-bottom:            6px;
@table-cell-padding-left:              20px;
@table-cell-padding-right:             20px;

//# параметры текста таблицы
@table-th-vertical-align:              middle;
@table-th-text-align:                  left;
@table-th-font-weight:                 700;
@table-th-color:                       @primary-color;

@table-td-vertical-align:              middle;
@table-td-text-align:                  left;
@table-td-font-weight:                 400;



//== цитаты
//##
@blockquote-font-size:                 @font-size * 1.25;
@blockquote-border-color:              @gray;

//== элемент важно и комментарий
//##
@important-background-color:           #f3481a;
@comment-background-color:             #244373;



/**
* 5. КОМПОНЕНТЫ (кнопки, хлебные крошки, пагинация, js-компоненты (слайдер, спойлер))
* -----------------------------------------------------------------------------
*/

//== СПОЙЛЕР
//
//## простой виджет (скрывает / показывает текст). Не путать с аккордеоном
//#
@spoiler-margin-top:                         0;
@spoiler-margin-bottom:                      20px;

@spoiler-title-normal-color:                 @link-color;
@spoiler-title-active-color:                 @link-hover;
@spoiler-title-hover-color:                  @link-hover;

@spoiler-title-font-weight:                  400;
@spoiler-title-font-size:                    18px;
@spoiler-title-border-width:                 1px;
@spoiler-title-border-type:                  dashed;
@spoiler-title-border-color:                 @link-color;



//== BREADCRUMB (ХЛЕБНЫЕ КРОШКИ)
//
//## Основные параметры хлебных крошек, которые меняются от сайта к сайту
//## Для детальных изменений меняйте шаблон wm.breadcrumb

@breadcrumb-color-normal:       #8a9fa7;
@breadcrumb-color-hover:        #424141;
@breadcrumb-text-transform:     none;
@breadcrumb-font-weight:        400;
@breadcrumb-font-size:          14px;
@breadcrumb-line-height:        18px;
@breadcrumb-padding-top:        20px;
@breadcrumb-padding-bottom:     35px;
@breadcrumb-icon-display:       inline-block; // "none" или "inline-block"
@breadcrumb-separator:          '/';



//== BUTTONS (КНОПКИ)
//
//## Основные параметры кнопок на сайте
//##
@button-padding-top:                10px;
@button-padding-bottom:             10px;
@button-padding-left:               15px;
@button-padding-right:              15px;

@button-border-width:               2px;
@button-border-style:               solid;
@button-border-radius:              0;

@button-font-size:                  16px;
@button-font-weight:                400;

@button-transition-duration:        0.3s;


//## small button
@button-sm-padding:                 4px 7px;
@button-sm-border-radius:           3px;
@button-sm-font-size:               14px;


//## button primary
@button-primary-color:              #fff;
@button-primary-bg:                 #ffbe0a;
@button-primary-border:             #ffbe0a;

@button-primary-color-hover:        #fff;
@button-primary-bg-hover:           #efb003;
@button-primary-border-hover:       #efb003;


//## button secondary
@button-secondary-color:              #fff;
@button-secondary-bg:                 @primary-color;
@button-secondary-border:             @primary-color;

@button-secondary-color-hover:        #fff;
@button-secondary-bg-hover:           lighten(@primary-color, 10);
@button-secondary-border-hover:       lighten(@primary-color, 10);


//## button transparent
@button-transparent-color:          #0082dd;
@button-transparent-border:         #0082dd;

@button-transparent-color-hover:    #fff;
@button-transparent-bg-hover:       #0082dd;
@button-transparent-border-hover:   #0082dd;


//## button disabled
@button-disabled-bg-color:          #bcbcbc;
@button-disabled-border-color:      #bcbcbc;
@button-disabled-color:             #7f7f7f;


//## button link
@button-link-text-decoration:    @link-text-decoration;

@button-link-color:              @link-color;
@button-link-bg:                 transparent;
@button-link-border:             transparent;

@button-link-color-hover:        @link-hover;
@button-link-bg-hover:           transparent;
@button-link-border-hover:       transparent;


//== Пагинация
//
//##
//#
@pagination-main-color:         @link-color;
@pagination-align:              center; // left, center, right
@pagination-margin-bottom:      @paragraph-margin-bottom * 2;
@pagination-font-size:          18px;
@pagination-font-weight:        500;
@pagination-text-color:         #000;
@pagination-control-size:       50px; // размер одного пункта (квадрат)

//== Табы
//
//##
//#
@tab-text-color:                #373737;
@tab-text-background-color:     transparent;
@tab-text-font-size:            16px;
@tab-text-transform:            uppercase;
@tab-control-max-width:         350px;


//== Аккордеон (на основе bootstrap collapse)
//
//## настроек нет
//#

//== формы
//
//##
//#
// label
@form-label-text-color:         #82888f;
@form-label-font-size:          14px;
@form-label-font-weight:        300;

// common colors (общие цвета контролов формы)
@form-focus-control-color:      @primary-color; // не должен быть красным
@form-error-border-color:       #d04035;
@form-error-background-color:   #f6dede;

// elements options
@form-input-height:             60px;
@form-elements-border-radius:   3px;
@form-textarea-min-height:      110px;
@form-textarea-resize:          vertical; //  none | both | horizontal | vertical | inherit



//== стилизация плагина select2
//
//##
//# сам блок
@select2-block-height:                      32px;
@select2-block-background-color:            #ececec;
@select2-block-border-color:                1px solid #aaa;
@select2-block-border-radius:               1px;
@select2-block-text-color:                  #646464;

//# список
@select2-optins-item-padding:               6px; // влияет на размер одного пункта option
@select2-options-background-color:          @primary-color-2; // при наведении на пункт
@select2-options-text-color:                #fff;

@select2-options-selected-background-color: #ddd; // выбранный пункт
@select2-options-selected-text-color:       #646464;
