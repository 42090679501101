/**
* modal form style
*
* Dependencies:
* 1. /src/components/wm.form/wm.form.less
* 2. /src/components/wm.buttons/wm.buttons.less
*/
.site-form-wrapper--modal {
  position: relative;
  background-color: #fff;
  max-width: 730px;
  margin: 15px auto;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  & .site-form__textarea {
    resize: none;
  }
}
.modal-form-message-info-wrapper {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  background-color: #fff;
  padding-left: 3.5%;
  padding-right: 3.5%;
  text-align: center;

  &.disabled {
    z-index: -1;
    visibility: hidden;
  }
}

.modal-form__message-info {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: @heading-color;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-form__message-caption {
  color: @text-color;
}
