.tab-content>.tab-pane {
  display: none;
}
.tab-content>.active {
  display: block;
}
.tab-pane.fade {
  opacity: 0;
  //transition: opacity .15s linear;
  transition: opacity 0s linear;
}
.tab-pane.fade.in {
  opacity: 1;
}
.tab-content {
  margin-bottom: 40px;

}
@tab-button-height: 50px;
.content .tab,
.tab {
  padding-left: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  margin-bottom: -2px;

  & li {
    flex-grow: 1;
    padding-left: 0;
    margin-right: 4px;
    max-width: @tab-control-max-width;
    height: @tab-button-height;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: none;
    }



    &.active {
      height: @tab-button-height + 5px;
      & a {
        cursor: default;
        background-color: #fff;
        height: @tab-button-height + 5px;
        border-bottom: 0px;
      }
      
    }
  }

  & a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: @tab-text-background-color;
    font-size: @tab-text-font-size;
    color: @tab-text-color;
    padding-top: 9px;
    padding-bottom: 7px;
    text-decoration: none;
    transition: 0s background-color ease-in;
    border: 2px solid #0082dd;
    height: @tab-button-height;

    &:hover {
      color: @primary-color;
    }
  }
}


.content .tab.tab--catalog,
.tab.tab--catalog {
  width: ~'calc(100% - 2px)';
  margin-bottom: 0;
  // display: table;
  // width: auto;
  // border-collapse: collapse;
  // box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);

  & li {
    // display: table-cell;
    border: 2px solid gray;
    border-bottom: 0;
    // margin-right: 0;
    border-left: 0;
    margin-right: 0;
    border-radius: 2px;
    position: relative;
    left: 2px;
    margin-left: -2px;
    border-left: 2px solid gray;

    &:first-child {
      border-left: 2px solid gray;
    }

    &.active {
      // position: relative;
      // top: 2px;
      // border-color: @primary-color;
      border: 2px solid @primary-color;
      border-bottom-color: #fff;
      // background-color: #fff;
      z-index: 10;
      top: 2px;
      margin-top: -2px;
      overflow: hidden;


      & a {
        // position: relative;
        // top: -2px;
        // left: -2px;
        position: relative;
        left: -2px;

        // box-shadow: 0 -5px 10px rgba(0,0,0,.1);
        background-color: #fff;
        color: @primary-color;
        border-left: 2px solid @primary-color;
      }
    }
  }

  a {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 2px solid transparent;
    transition: none;
  }

  & + .tab-content {
    min-height: 200px;
    border: 2px solid @primary-color;
    padding: 22px 15px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .tab,
  .tab {
    flex-wrap: wrap;

    & li {
      max-width: 100%;
      flex-basis: 100%;
      width: 100%;
      margin-right: 0;
      border: 1px solid @gray--darken;
      border-bottom: 0;

      &:last-child {
        border-bottom: 1px solid @gray--darken;
      }
    }
  }
  .content .tab.tab--catalog,
  .tab.tab--catalog {
    & li {
      border: 1px solid @gray--darken;
      border-bottom: 0;
      border-radius: 0;

      &:first-child {
        border-left: 1px solid @gray--darken;
      }

      &:last-child {
        border-bottom: 1px solid @gray--darken;
      }
      &.active {
        border: 1px solid @gray--darken;
        border-bottom: 0;

        &:last-child {
          border-bottom: 1px solid @gray--darken;
        }
        & a {
          position: static;
          background-color: @primary-color;
          color: #fff;
          border: 0;
        }
      }
    }
    & a {
      position: static;
    }
  }
  .content .tab.tab--catalog+.tab-content,
  .tab.tab--catalog+.tab-content {
    box-shadow: none;
    border: 0;
    min-height: 100%;
    padding: 20px 0 40px;
  }
}
.tab-pane {
  background-color: #fff;
  border: 2px solid #0082dd;
  padding: 16px;
}

