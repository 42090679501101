.breadcrumb {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: @breadcrumb-padding-top;
  padding-bottom: @breadcrumb-padding-bottom;
  margin: 0;

  & a {
    font-size: @breadcrumb-font-size;
    line-height: @breadcrumb-line-height;
    font-weight: @breadcrumb-font-weight;
    text-transform: @breadcrumb-text-transform;
    color: @breadcrumb-color-normal;
    text-decoration: none;
    padding-right: 5px;

    &[href] {
      & span {
        text-decoration: underline;
      }
    }

    & span {
      overflow: hidden;
    }

    &[href]:hover {
      color: @breadcrumb-color-hover;
    }
    &::after {
      display: inline-block;
      content: @breadcrumb-separator;
      padding-left: 5px;
      text-decoration: none;
    }
  }
  & li {
    float: left;

    &:last-child a::after {
      display: none;
    }
    &.active a {
      color: @breadcrumb-color-hover;
      & span {
        text-decoration: none;
      }
    }
  }
}
