@font-face {
  font-family: "PTSans-caption";
  src: url("../fonts/ptsans-caption/ptsans-caption-regular/ptsans-caption-regular.woff2?v=1.1.0") format("woff2"),
  url("../fonts/ptsans-caption/ptsans-caption-regular/ptsans-caption-regular.woff?v=1.1.0") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PTSans-caption";
  src: url("../fonts/ptsans-caption/ptsans-caption-bold/ptsans-caption-bold.woff2") format("woff2"),
  url("../fonts/ptsans-caption/ptsans-caption-bold/ptsans-caption-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
