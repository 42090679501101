.ui-carousel {
	height: 100px;
	margin: 0;
	overflow: auto;
	padding: 0;
	position: relative; /*for ie7 to work e.g in html carousels*/
	width: 300px
}

.ui-carousel > .wrapper {
    margin: 0;
    padding: 0;
    width: 9999px;
}

.ui-carousel > .wrapper > * {
	border: 0;
	display: block;
	float: left;
	height: 100px;
	overflow: hidden;
	width: 100px;	
}