/**
* Таблица стилей содержит стили типографики для визуального редактора CKEditor
* переменные редактируются из файла ./src/less/variables.less
*/
/**
* Основные настройки контентной области
*/
.content {
  /**
  * учитывает последний margin-bottom у строчного элемента
  */
  height: 100%;
  color: @text-color;
}
/**
* Основной текст
*/
.content p,
.p {
  margin-top: @paragraph-margin-top;
  margin-bottom: @paragraph-margin-bottom;

  &.lead {
    font-size: @paragraph-lead-font-size;
  }
}
/**
* Заголовки
* -----------------------------------------------------------------------------
*/
.content .h1,
.content h1,
.h1,
h1 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h1-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}

.content .h2,
.content h2,
.h2,
h2 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h2-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}

.content .h3,
.content h3,
.h3,
h3 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h3-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}

.content .h4,
.content h4,
.h4,
h4 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h4-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}

.content .h5,
.content h5,
.h5,
h5 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h5-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}

.content .h6,
.content h6,
.h6,
h6 {
  font-family: @main-font;
  color: @heading-color;
  font-size: @heading-h6-font-size;
  line-height: 1.1;
  font-weight: @heading-font-weight;
  margin-top: @heading-margin-top;
  margin-bottom: @heading-margin-bottom;

  & small {
    font-size: 65%;
    font-weight: 400;
    line-height: 1;
    color: @text-color;
  }
}
/**
* Оформление текста внутри абзаца
* -----------------------------------------------------------------------------
*/
b,
strong {
  font-weight: bolder;
}

cite,
dfn,
em,
i,
var {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.mark,
mark {
  padding: 0.2rem;
  color: @text-color;
  background-color: #ff0;
}
/**
* листинги кода
* -----------------------------------------------------------------------------
*/
code,
pre {
  font-size: 0.9rem;
  padding: 3px 8px 1px;
  background-color: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #c7254e;
}

code,
kbd,
pre,
samp {
  font-family: @mono-font;
}
/**
* ссылки
* -----------------------------------------------------------------------------
*/
.a,
.content a {
  color: @link-color;
  font-size: @link-font-size;
  font-weight: @link-font-weight;
  text-decoration: @link-text-decoration;
  transition: 0.1s color ease;

  &:active {
    color: @link-active;
  }

  &:visited {
    color: @link-visited;
  }

  &.hover,
  &:hover {
    color: @link-hover;
    text-decoration: underline;
  }
}
/**
* списки
* -----------------------------------------------------------------------------
*/
.content {
  & ul {
    margin: 0 0 @list-margin-bottom @unordered-list-margin-left;
    padding: 0 0 0 @unordered-list-padding-left;
    list-style-type: none;

    & li {
      position: relative;
      margin-bottom: 0;
      line-height: @unordered-list-line-height;
      /**
      * место под маркер списка
      */
      padding-left: @unordered-item-padding-left;
    }

    & ul {
      margin: 0;
      padding-top: 0;
      padding-left: 0;

      & li {
        margin-bottom: 0;

        &::before {
          background-color: transparent;
        }
      }
    }

    & li::before {
      content: '';
      position: absolute;
      top: @unordered-list-item-marker-top;
      left: 0;
      width: @unordered-list-item-marker-width;
      height: @unordered-list-item-marker-height;
      background-color: @unordered-list-marker-color;
      border: 1px solid @unordered-list-marker-color;
      border-radius: @unordered-list-item-border-radius;
    }
  }

  & ol {
    margin: 0 0 @list-margin-bottom;
    padding: 0 0 0 @ordered-list-padding-left;
    list-style-type: none;
    /**
    * Задаём имя счетчика
    */
    counter-reset: num;
    & li {
      position: relative;
      padding-left: @ordered-list-item-padding-left;
      margin-bottom: 0;
    }

    & ol {
      margin: 0;
      padding-left: 10px;
      padding-top: 0;
      list-style-type: none;
      /**
      * Задаём имя счетчика
      */
      counter-reset: num;
      & li {
        margin-bottom: 0;
        line-height: @ordered-item-line-height;
      }
      /**
      * многоуровневый нумерованный пункт списка
      */
      li::before {
        /**
        * Выводим число
        */
        content: counter(num) '.';
        /**
        * Увеличиваем значение счётчика
        */
        counter-increment: num;
        display: inline-block;
        vertical-align: baseline;
        margin-right: 2px;
        color: @ordered-list-item-marker-color;
        font-size: @ordered-item-marker-font-size;
        font-weight: @ordered-item-markger-font-weight;
        line-height: @ordered-item-line-height;
      }
    }

    & li::before {
      /**
      * Выводим число
      */
      content: counter(num) '.';
      /**
      * Увеличиваем значение счётчика
      */
      counter-increment: num;
      position: absolute;
      top: 0;
      left: 0;
      margin-right: 5px;
      width: 22px;
      text-align: right;
      color: @ordered-list-item-marker-color;
      font-size: @ordered-item-marker-font-size;
      font-weight: @ordered-item-markger-font-weight;
      line-height: @ordered-item-line-height;
    }
  }
}
/**
* таблица
* -----------------------------------------------------------------------------
*/
.content {
  & table {
    width: 100%;

    &.table-striped {
      & tr:nth-child(even) {
        background-color: @table-striped-background-color;
      }
    }

    &.table-hover {
      & tr:hover {
        background-color: @table-tr-hover-background-color;
      }
    }
  }
}
/**
* только для настоящих таблиц
*/
table.table {
  table-layout: fixed;
  border-spacing: @table-border-spacing;
  margin-bottom: @table-margin-bottom;

  & th {
    padding: @table-cell-padding-top @table-cell-padding-right @table-cell-padding-bottom @table-cell-padding-left;
    font-weight: @table-th-font-weight;
    color: @table-th-color;
    text-align: @table-th-text-align;
    vertical-align: @table-th-vertical-align;
  }

  & td {
    padding: @table-cell-padding-top @table-cell-padding-right @table-cell-padding-bottom @table-cell-padding-left;
    font-weight: @table-td-font-weight;
    text-align: @table-td-text-align;
    vertical-align: @table-td-vertical-align;
  }
}
/**
* цитата blockquote
* -----------------------------------------------------------------------------
*/
.content {
  & blockquote {
    font-size: @blockquote-font-size;
    border-left-color: @blockquote-border-color;
    border-left-style: solid;
    border-left-width: 5px;
    padding: 12px 2%;
    margin: 0 2% 20px;

    & footer,
    & small {
      font-size: @blockquote-font-size * 0.8;

      &::before {
        content: '\2014 \00A0';
      }
    }
  }
}
/**
* элемент важно и комментарий
* -----------------------------------------------------------------------------
*/
.content .important,
.important {
  background-color: @important-background-color;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;

  & h3 {
    color: #fff;
  }

  & p {
    margin-bottom: 0;
  }
}

.comment,
.content .comment {
  background-color: @comment-background-color;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;

  & .h3,
  & h3 {
    color: #fff;
  }

  & p {
    margin-bottom: 0;
    font-style: italic;
  }
}
/**
* сноски (footnotes)
* -----------------------------------------------------------------------------
*/
.content {
  .footnote {
    color: @text-color;
    text-decoration: none;

    &:hover {
      color: @link-hover;
    }

    &:visited {
      color: @text-color;
    }

    & sup {
      color: @link-hover;
    }
  }

  .footnotes {
    & li {
      font-size: 14px;

      &::before {
        font-size: 14px;
      }
    }
  }
}

.content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1024px) {
  code,
  pre {
    display: block;
    overflow-x: scroll;
    padding: 10px;
  }

  .content table.table {
    width: auto;
    max-width: 100%;
  }

  .table-responsive {
    /**
    * тень выравнивается относительно этого блока
    */
    position: relative;
    /**
    * нужно для того чтоб тень не прокручивалась
    */
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .table-responsive div {
    /**
    * этот див нужен для тени
    */
    overflow-x: auto;
  }

  /**
  * тень остается на одном месте благодаря вложенному диву
  */
  .table-responsive div::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 30px;
    height: 100%;
    /**
    * тильда и ковычки это для less-файлов
    */
    border-radius: ~"10px 0 0 10px / 50% 0 0 50%";
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
  }

  .content ol,
  .content ul {
    margin-left: 1%;
    padding-left: 1%;
  }

  .content ol ol,
  .content ul ul {
    padding-left: 0;
  }

  .content ul li {
    padding-left: 25px;
  }
}
