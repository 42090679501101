/**
* form style
*/
.site-form-wrapper {
  border: 2px solid @form-focus-control-color;
  margin-bottom: 40px;
}
.site-form {
  padding-top: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.site-form-element-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.site-form__label {
  display: block;
  width: 100%;
  font-size: @form-label-font-size;
  font-weight: @form-label-font-weight;
  color: @form-label-text-color;
}

.site-form__input {
  display: block;
  width: 100%;
  height: @form-input-height;
  border: 1px solid @gray--darken;
  border-radius: @form-elements-border-radius;
  background-color: @gray;
  padding-left: 15px;
  padding-right: 15px;

  transition-duration: 0.15s;
  transition-property: background-color;
  transition-timing-function: ease-in;

  &:focus {
    outline: 0;
    border-color: @form-focus-control-color;
    background-color: #fff;
  }
  &:hover {
    background-color: #fff;
  }
}

.site-form__textarea {
  display: block;
  width: 100%;
  min-height: @form-textarea-min-height;
  background-color: @gray;
  border: 1px solid @gray--darken;
  border-radius: @form-elements-border-radius;
  padding: 15px;
  resize: @form-textarea-resize;

  transition-duration: 0.15s;
  transition-property: background-color;
  transition-timing-function: ease-in;

  &:focus {
    border-color: @form-focus-control-color;
    outline: 0;
    background-color: #fff;
  }

  &:hover {
    background-color: #fff;
  }
}

.site-form__input.error {
  border-color: @form-error-border-color;
  background-color: @form-error-background-color;
  &:focus {
    outline: 0;
  }
}

.site-form label.error {
  left: @grid-gutter-width / 2;
  color: @form-error-border-color;
}

.content .site-form__button-submit,
.site-form__button-submit {
  padding-left: 105px;
  padding-right: 105px;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
* checkbox
*/
.modal-form-row--checkbox {
  position: relative; /* for label with error */
  display: flex;
  margin-bottom: 60px;
}

.modal-form-row--checkbox label.error {
  position: absolute;
  left: 0;
  top: 100%;
}

.modal-form__label-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  min-width: 21px;
  border-radius: 3px;
  border: 1px solid @gray--darken;
  background-color: @gray;
  padding: 3px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.15s border-color ease-in;
}

.modal-form__icon-checked {
  width: 20px;
  height: 20px;
  fill: @primary-color;
  transition: 0.15s opacity ease-in;
  opacity: 0;
}

.modal-form__input-checkbox {
  &:checked {
    & ~ .modal-form__label-checkbox .modal-form__icon-checked {
      opacity: 1;
    }
  }
  &:focus {
    & ~ .modal-form__label-checkbox {
      border-color: @form-focus-control-color;
    }
  }
  &.error ~ .modal-form__label-checkbox{
    border-color: @form-error-border-color;
    background-color: @form-error-background-color;
  }
}

.modal-form-checkbox-text {
  font-size: 12px;
  color: #818080;
  font-weight: 300;

  & a {
    font-size: 12px;
  }
}

.modal-form-checkbox-text__link {
  color: @link-color;
  text-decoration: underline;
  &:hover {
    color: @link-hover;
  }
}


@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .content .site-form__button-submit,
  .site-form__button-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
}
