@charset "UTF-8";
/**
* вендоры вебмотор
*/
/* Вендоры, стили разных подключаемых библиотек */
/**
* специально для irs
*/

/*Eric Meyers CSS reset  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display definitions */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
}
[hidden] {
  display: none;
}
/* Base elements style */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  min-height: 100%;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-loading .slick-list {
  background: #fff url('../img/ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-next:focus:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
.slick-prev[dir="rtl"] {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "";
}
.slick-prev:before[dir="rtl"] {
  content: "";
}
.slick-next {
  right: -25px;
}
.slick-next[dir="rtl"] {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "";
}
.slick-next:before[dir="rtl"] {
  content: "";
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 1;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:after,
.slick-track:before {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.ui-carousel {
	height: 100px;
	margin: 0;
	overflow: auto;
	padding: 0;
	position: relative; /*for ie7 to work e.g in html carousels*/
	width: 300px
}

.ui-carousel > .wrapper {
    margin: 0;
    padding: 0;
    width: 9999px;
}

.ui-carousel > .wrapper > * {
	border: 0;
	display: block;
	float: left;
	height: 100px;
	overflow: hidden;
	width: 100px;	
}
/* переменные */
/**
* 3. ШРИФТЫ
* -----------------------------------------------------------------------------
*/
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
/**
* 4. ТИПОГРАФИКА
* -----------------------------------------------------------------------------
*/
/**
* 5. КОМПОНЕНТЫ (кнопки, хлебные крошки, пагинация, js-компоненты (слайдер, спойлер))
* -----------------------------------------------------------------------------
*/
/* модульная сетка на flexbox */
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}
.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}
.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (min-width: 768px) {
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 1200px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
/* шрифты */
@font-face {
  font-family: "PTSans-caption";
  src: url("../fonts/ptsans-caption/ptsans-caption-regular/ptsans-caption-regular.woff2?v=1.1.0") format("woff2"), url("../fonts/ptsans-caption/ptsans-caption-regular/ptsans-caption-regular.woff?v=1.1.0") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PTSans-caption";
  src: url("../fonts/ptsans-caption/ptsans-caption-bold/ptsans-caption-bold.woff2") format("woff2"), url("../fonts/ptsans-caption/ptsans-caption-bold/ptsans-caption-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
/**
* rouble font
*/
/* типографика сайта */
/**
* Таблица стилей содержит стили типографики для визуального редактора CKEditor
* переменные редактируются из файла ./src/less/variables.less
*/
/**
* Основные настройки контентной области
*/
.content {
  /**
  * учитывает последний margin-bottom у строчного элемента
  */
  height: 100%;
  color: #373737;
}
/**
* Основной текст
*/
.content p,
.p {
  margin-top: 0;
  margin-bottom: 20px;
}
.content p.lead,
.p.lead {
  font-size: 24px;
}
/**
* Заголовки
* -----------------------------------------------------------------------------
*/
.content .h1,
.content h1,
.h1,
h1 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h1 small,
.content h1 small,
.h1 small,
h1 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
.content .h2,
.content h2,
.h2,
h2 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h2 small,
.content h2 small,
.h2 small,
h2 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
.content .h3,
.content h3,
.h3,
h3 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 28px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h3 small,
.content h3 small,
.h3 small,
h3 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
.content .h4,
.content h4,
.h4,
h4 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 20px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h4 small,
.content h4 small,
.h4 small,
h4 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
.content .h5,
.content h5,
.h5,
h5 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h5 small,
.content h5 small,
.h5 small,
h5 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
.content .h6,
.content h6,
.h6,
h6 {
  font-family: "PTSans-caption", Helvetica, Tahoma, Arial, sans-serif;
  color: #222;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 35px;
}
.content .h6 small,
.content h6 small,
.h6 small,
h6 small {
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
  color: #373737;
}
/**
* Оформление текста внутри абзаца
* -----------------------------------------------------------------------------
*/
b,
strong {
  font-weight: bolder;
}
cite,
dfn,
em,
i,
var {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
.mark,
mark {
  padding: 0.2rem;
  color: #373737;
  background-color: #ff0;
}
/**
* листинги кода
* -----------------------------------------------------------------------------
*/
code,
pre {
  font-size: 0.9rem;
  padding: 3px 8px 1px;
  background-color: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 3px;
  color: #c7254e;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
/**
* ссылки
* -----------------------------------------------------------------------------
*/
.a,
.content a {
  color: #0082dd;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.1s color ease;
}
.a:active,
.content a:active {
  color: #0082dd;
}
.a:visited,
.content a:visited {
  color: #0082dd;
}
.a.hover,
.content a.hover,
.a:hover,
.content a:hover {
  color: #0082dd;
  text-decoration: underline;
}
/**
* списки
* -----------------------------------------------------------------------------
*/
.content ul {
  margin: 0 0 20px 0;
  padding: 0 0 0 0;
  list-style-type: none;
}
.content ul li {
  position: relative;
  margin-bottom: 0;
  line-height: 24px;
  /**
      * место под маркер списка
      */
  padding-left: 28px;
}
.content ul ul {
  margin: 0;
  padding-top: 0;
  padding-left: 0;
}
.content ul ul li {
  margin-bottom: 0;
}
.content ul ul li::before {
  background-color: transparent;
}
.content ul li::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #fab908;
  border: 1px solid #fab908;
  border-radius: 0;
}
.content ol {
  margin: 0 0 20px;
  padding: 0 0 0 4px;
  list-style-type: none;
  /**
    * Задаём имя счетчика
    */
  counter-reset: num;
}
.content ol li {
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
}
.content ol ol {
  margin: 0;
  padding-left: 10px;
  padding-top: 0;
  list-style-type: none;
  /**
      * Задаём имя счетчика
      */
  counter-reset: num;
  /**
      * многоуровневый нумерованный пункт списка
      */
}
.content ol ol li {
  margin-bottom: 0;
  line-height: 24px;
}
.content ol ol li::before {
  /**
        * Выводим число
        */
  content: counter(num) '.';
  /**
        * Увеличиваем значение счётчика
        */
  counter-increment: num;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 2px;
  color: #00384e;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.content ol li::before {
  /**
      * Выводим число
      */
  content: counter(num) '.';
  /**
      * Увеличиваем значение счётчика
      */
  counter-increment: num;
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 5px;
  width: 22px;
  text-align: right;
  color: #00384e;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
/**
* таблица
* -----------------------------------------------------------------------------
*/
.content table {
  width: 100%;
}
.content table.table-striped tr:nth-child(even) {
  background-color: #f5f5f5;
}
.content table.table-hover tr:hover {
  background-color: #f5f5f5;
}
/**
* только для настоящих таблиц
*/
table.table {
  table-layout: fixed;
  border-spacing: 2px 2px;
  margin-bottom: 50px;
}
table.table th {
  padding: 6px 20px 6px 20px;
  font-weight: 700;
  color: #00384e;
  text-align: left;
  vertical-align: middle;
}
table.table td {
  padding: 6px 20px 6px 20px;
  font-weight: 400;
  text-align: left;
  vertical-align: middle;
}
/**
* цитата blockquote
* -----------------------------------------------------------------------------
*/
.content blockquote {
  font-size: 20px;
  border-left-color: #f6f6f6;
  border-left-style: solid;
  border-left-width: 5px;
  padding: 12px 2%;
  margin: 0 2% 20px;
}
.content blockquote footer,
.content blockquote small {
  font-size: 16px;
}
.content blockquote footer::before,
.content blockquote small::before {
  content: '\2014 \00A0';
}
/**
* элемент важно и комментарий
* -----------------------------------------------------------------------------
*/
.content .important,
.important {
  background-color: #f3481a;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;
}
.content .important h3,
.important h3 {
  color: #fff;
}
.content .important p,
.important p {
  margin-bottom: 0;
}
.comment,
.content .comment {
  background-color: #244373;
  color: #fff;
  padding: 32px 8.5%;
  margin-bottom: 40px;
}
.comment .h3,
.content .comment .h3,
.comment h3,
.content .comment h3 {
  color: #fff;
}
.comment p,
.content .comment p {
  margin-bottom: 0;
  font-style: italic;
}
/**
* сноски (footnotes)
* -----------------------------------------------------------------------------
*/
.content .footnote {
  color: #373737;
  text-decoration: none;
}
.content .footnote:hover {
  color: #0082dd;
}
.content .footnote:visited {
  color: #373737;
}
.content .footnote sup {
  color: #0082dd;
}
.content .footnotes li {
  font-size: 14px;
}
.content .footnotes li::before {
  font-size: 14px;
}
.content img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 1024px) {
  code,
  pre {
    display: block;
    overflow-x: scroll;
    padding: 10px;
  }
  .content table.table {
    width: auto;
    max-width: 100%;
  }
  .table-responsive {
    /**
    * тень выравнивается относительно этого блока
    */
    position: relative;
    /**
    * нужно для того чтоб тень не прокручивалась
    */
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
  .table-responsive div {
    /**
    * этот див нужен для тени
    */
    overflow-x: auto;
  }
  /**
  * тень остается на одном месте благодаря вложенному диву
  */
  .table-responsive div::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 30px;
    height: 100%;
    /**
    * тильда и ковычки это для less-файлов
    */
    border-radius: 10px 0 0 10px / 50% 0 0 50%;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
  }
  .content ol,
  .content ul {
    margin-left: 1%;
    padding-left: 1%;
  }
  .content ol ol,
  .content ul ul {
    padding-left: 0;
  }
  .content ul li {
    padding-left: 25px;
  }
}
/* классы - helper'ы */
.hidden {
  display: none !important;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.no-padding {
  padding: 0;
}
.text-align-center {
  text-align: center;
}
/* подключаемые КОМПОНЕНТЫ */
.breadcrumb {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  padding-bottom: 35px;
  margin: 0;
}
.breadcrumb a {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: none;
  color: #8a9fa7;
  text-decoration: none;
  padding-right: 5px;
}
.breadcrumb a[href] span {
  text-decoration: underline;
}
.breadcrumb a span {
  overflow: hidden;
}
.breadcrumb a[href]:hover {
  color: #424141;
}
.breadcrumb a::after {
  display: inline-block;
  content: '/';
  padding-left: 5px;
  text-decoration: none;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li:last-child a::after {
  display: none;
}
.breadcrumb li.active a {
  color: #424141;
}
.breadcrumb li.active a span {
  text-decoration: none;
}
/**
* wm buttons
*/
.btn,
.content .btn {
  display: inline-block;
  min-width: 155px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.1;
  text-decoration: none;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-property: border-color, background-color;
}
.btn--sm,
.content .btn--sm {
  padding: 4px 7px;
  border-radius: 3px;
  font-size: 14px;
}
/**
* button primary
*/
.btn--primary,
.content .btn--primary {
  border-color: #ffbe0a;
  background-color: #ffbe0a;
  color: #fff;
}
.btn--primary:hover,
.content .btn--primary:hover {
  border-color: #efb003;
  background-color: #efb003;
  color: #fff;
}
.btn--primary:visited,
.content .btn--primary:visited {
  color: #fff;
}
/**
* button secondary
*/
.btn--secondary,
.content .btn--secondary {
  border-color: #00384e;
  background-color: #00384e;
  color: #fff;
}
.btn--secondary:hover,
.content .btn--secondary:hover {
  border-color: #005d81;
  background-color: #005d81;
  color: #fff;
}
.btn--secondary:visited,
.content .btn--secondary:visited {
  color: #fff;
}
/**
* button transparent
*/
.btn--transparent,
.content .btn--transparent {
  border-color: #0082dd;
  background-color: transparent;
  color: #0082dd;
}
.btn--transparent:hover,
.content .btn--transparent:hover {
  border-color: #0082dd;
  background-color: #0082dd;
  color: #fff;
}
.btn--transparent:visited,
.content .btn--transparent:visited {
  color: #0082dd;
}
.btn--transparent.btn--primary,
.content .btn--transparent.btn--primary {
  border-color: #ffbe0a;
  color: #ffbe0a;
}
.btn--transparent.btn--secondary,
.content .btn--transparent.btn--secondary {
  border-color: #00384e;
  color: #00384e;
}
/**
* button disabled
*/
.btn.btn--disabled,
.btn[disabled],
.content .btn[disabled],
.content .btn.btn--disabled {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
  background-color: #bcbcbc;
  border-color: #bcbcbc;
  color: #7f7f7f;
}
.btn.btn--disabled:hover,
.btn[disabled]:hover,
.content .btn[disabled]:hover,
.content .btn.btn--disabled:hover {
  border-color: #bcbcbc;
  background-color: #bcbcbc;
  color: #7f7f7f;
}
.btn.btn--disabled:visited,
.btn[disabled]:visited,
.content .btn[disabled]:visited,
.content .btn.btn--disabled:visited {
  color: #7f7f7f;
}
/**
* основная кнопка
*/
.btn--link,
.content .btn--link {
  border-color: transparent;
  background-color: transparent;
  color: #0082dd;
  text-decoration: none;
}
.btn--link:hover,
.content .btn--link:hover {
  border-color: transparent;
  background-color: transparent;
  color: #0082dd;
}
.btn--link:focus,
.content .btn--link:focus {
  border-color: transparent;
  background-color: transparent;
  color: #0082dd;
}
.btn--link:visited,
.content .btn--link:visited {
  color: #0082dd;
}
@media (max-width: 1024px) {
  .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 768px) {
  /* 768px по умолчанию */
  .btn,
  .content .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.spoiler {
  margin-top: 0;
  margin-bottom: 20px;
}
.spoiler__text {
  display: none;
  padding-top: 20px;
}
.spoiler__title {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  color: #0082dd;
  font-weight: 400;
  font-size: 18px;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #0082dd;
  cursor: pointer;
}
.spoiler-title-icon-wrapper {
  position: absolute;
  top: 3px;
  right: -25px;
  width: 14px;
  height: 14px;
  fill: #0082dd;
}
.spoiler-title-icon-wrapper .icon {
  width: 14px;
  height: 14px;
  transition: 0.15s transform ease;
}
.spoiler__title:hover {
  color: #0082dd;
  border-bottom-color: #0082dd;
}
.spoiler__title:hover .icon {
  fill: #0082dd;
}
.spoiler.active .spoiler__text {
  display: block;
}
.spoiler.active .spoiler__title {
  color: #0082dd;
  border-bottom-color: #0082dd;
}
.spoiler.active .icon {
  fill: #0082dd;
  transform: rotate(180deg);
}
.pagination-wrapper {
  text-align: center;
}
.pagination,
.content .pagination {
  margin-bottom: 40px;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  padding-left: 0;
}
.pagination li,
.content .pagination li {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 0;
  padding-left: 0;
}
.pagination li::before,
.content .pagination li::before {
  display: none !important;
}
.pagination li:last-child,
.content .pagination li:last-child {
  margin-right: 0;
}
.pagination a,
.content .pagination a,
.pagination span,
.content .pagination span {
  display: block;
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  border-bottom: 3px solid #0082dd;
  background: transparent;
  transition: 0.15s background-color ease-in;
}
.pagination a:visited,
.content .pagination a:visited,
.pagination span:visited,
.content .pagination span:visited {
  color: #000;
}
.pagination a:hover,
.content .pagination a:hover,
.pagination span:hover,
.content .pagination span:hover {
  background-color: #0082dd;
  border-bottom-color: #0082dd;
  color: #fff;
}
.pagination a.active,
.content .pagination a.active,
.pagination span.active,
.content .pagination span.active {
  background-color: #0082dd;
  color: #fff;
  cursor: default;
}
.pagination a.disabled,
.content .pagination a.disabled,
.pagination span.disabled,
.content .pagination span.disabled {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination a.disabled .icon,
.content .pagination a.disabled .icon,
.pagination span.disabled .icon,
.content .pagination span.disabled .icon {
  fill: #777;
}
.pagination .pagination__prev,
.content .pagination .pagination__prev,
.pagination .pagination__next,
.content .pagination .pagination__next {
  border-bottom: 0;
}
.pagination .pagination__prev .icon,
.content .pagination .pagination__prev .icon,
.pagination .pagination__next .icon,
.content .pagination .pagination__next .icon {
  width: 50px;
  height: 50px;
  fill: #0082dd;
  transition: 0.15s fill ease-in;
}
.pagination .pagination__prev:hover,
.content .pagination .pagination__prev:hover,
.pagination .pagination__next:hover,
.content .pagination .pagination__next:hover {
  background-color: transparent;
  color: inherit;
}
.pagination__prev .icon {
  transform: rotate(-180deg);
}
@media (max-width: 1024px) {
  .content .pagination,
  .pagination {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .content .pagination li,
  .pagination li {
    margin-bottom: 20px;
  }
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.tab-pane.fade {
  opacity: 0;
  transition: opacity 0s linear;
}
.tab-pane.fade.in {
  opacity: 1;
}
.tab-content {
  margin-bottom: 40px;
}
.content .tab,
.tab {
  padding-left: 0;
  margin-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 100%;
  margin-bottom: -2px;
}
.content .tab li,
.tab li {
  flex-grow: 1;
  padding-left: 0;
  margin-right: 4px;
  max-width: 350px;
  height: 50px;
  position: relative;
}
.content .tab li:last-child,
.tab li:last-child {
  margin-right: 0;
}
.content .tab li::before,
.tab li::before {
  content: none;
}
.content .tab li.active,
.tab li.active {
  height: 55px;
}
.content .tab li.active a,
.tab li.active a {
  cursor: default;
  background-color: #fff;
  height: 55px;
  border-bottom: 0px;
}
.content .tab a,
.tab a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: transparent;
  font-size: 16px;
  color: #373737;
  padding-top: 9px;
  padding-bottom: 7px;
  text-decoration: none;
  transition: 0s background-color ease-in;
  border: 2px solid #0082dd;
  height: 50px;
}
.content .tab a:hover,
.tab a:hover {
  color: #00384e;
}
.content .tab.tab--catalog,
.tab.tab--catalog {
  width: calc(100% - 2px);
  margin-bottom: 0;
}
.content .tab.tab--catalog li,
.tab.tab--catalog li {
  border: 2px solid gray;
  border-bottom: 0;
  border-left: 0;
  margin-right: 0;
  border-radius: 2px;
  position: relative;
  left: 2px;
  margin-left: -2px;
  border-left: 2px solid gray;
}
.content .tab.tab--catalog li:first-child,
.tab.tab--catalog li:first-child {
  border-left: 2px solid gray;
}
.content .tab.tab--catalog li.active,
.tab.tab--catalog li.active {
  border: 2px solid #00384e;
  border-bottom-color: #fff;
  z-index: 10;
  top: 2px;
  margin-top: -2px;
  overflow: hidden;
}
.content .tab.tab--catalog li.active a,
.tab.tab--catalog li.active a {
  position: relative;
  left: -2px;
  background-color: #fff;
  color: #00384e;
  border-left: 2px solid #00384e;
}
.content .tab.tab--catalog a,
.tab.tab--catalog a {
  padding-left: 15px;
  padding-right: 15px;
  border-left: 2px solid transparent;
  transition: none;
}
.content .tab.tab--catalog + .tab-content,
.tab.tab--catalog + .tab-content {
  min-height: 200px;
  border: 2px solid #00384e;
  padding: 22px 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
@media (max-width: 768px) {
  /* 768px по умолчанию */
  .content .tab,
  .tab {
    flex-wrap: wrap;
  }
  .content .tab li,
  .tab li {
    max-width: 100%;
    flex-basis: 100%;
    width: 100%;
    margin-right: 0;
    border: 1px solid #adadad;
    border-bottom: 0;
  }
  .content .tab li:last-child,
  .tab li:last-child {
    border-bottom: 1px solid #adadad;
  }
  .content .tab.tab--catalog li,
  .tab.tab--catalog li {
    border: 1px solid #adadad;
    border-bottom: 0;
    border-radius: 0;
  }
  .content .tab.tab--catalog li:first-child,
  .tab.tab--catalog li:first-child {
    border-left: 1px solid #adadad;
  }
  .content .tab.tab--catalog li:last-child,
  .tab.tab--catalog li:last-child {
    border-bottom: 1px solid #adadad;
  }
  .content .tab.tab--catalog li.active,
  .tab.tab--catalog li.active {
    border: 1px solid #adadad;
    border-bottom: 0;
  }
  .content .tab.tab--catalog li.active:last-child,
  .tab.tab--catalog li.active:last-child {
    border-bottom: 1px solid #adadad;
  }
  .content .tab.tab--catalog li.active a,
  .tab.tab--catalog li.active a {
    position: static;
    background-color: #00384e;
    color: #fff;
    border: 0;
  }
  .content .tab.tab--catalog a,
  .tab.tab--catalog a {
    position: static;
  }
  .content .tab.tab--catalog + .tab-content,
  .tab.tab--catalog + .tab-content {
    box-shadow: none;
    border: 0;
    min-height: 100%;
    padding: 20px 0 40px;
  }
}
.tab-pane {
  background-color: #fff;
  border: 2px solid #0082dd;
  padding: 16px;
}
.panel-group {
  margin-bottom: 40px;
}
.panel-group .panel {
  margin-bottom: 20px;
  border-radius: 0;
}
.panel-group .panel-title {
  margin-bottom: 0;
}
.panel-group .panel-title a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  text-decoration: none;
  background-color: #f6f6f6;
  transition: 0.15s background-color ease-in;
}
.panel-group .panel-title a .panel-title-link__text {
  color: #0082dd;
}
.panel-group .panel-title a:hover .panel-title-link__text {
  color: #00384e;
}
.panel-group .panel-title a:not(.collapsed) .panel-title-link__text {
  color: #00384e;
  font-weight: 500;
  background-color: transparent;
}
.panel-group .panel-title a:not(.collapsed) .panel-title-link__icon .icon {
  width: 35px;
  height: 35px;
  fill: #00384e;
  transform: rotate(0);
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #adadad;
}
.panel-body {
  padding: 10px 15px 25px;
}
.panel-default {
  border: 1px solid #adadad;
}
.panel-default.active {
  border-color: #00384e;
  outline: 1px solid #00384e;
}
.collapse.in {
  display: block;
}
.collapse {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: .35s;
  transition-property: height,visibility;
}
.panel-title-link__icon {
  height: 31px;
}
.panel-title-link__icon .icon {
  width: 31px;
  height: 31px;
  fill: #0082dd;
  transform: rotate(-45deg);
  transition: 0.15s transform ease-in;
}
.panel-title-link__text {
  transition: 0.15s color ease-in;
}
.tooltip {
  position: absolute;
  z-index: 2000;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  opacity: 0;
  line-break: auto;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #adadad;
}
.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #adadad;
}
.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #adadad;
}
.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #adadad;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  display: none;
}
.tooltip-inner {
  width: 200px;
  padding: 6px 12px;
  color: #9d9d9d;
  text-align: center;
  background-color: #fff;
  border: 1px solid #adadad;
}
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9;
}
.fade.in {
  opacity: 1;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear;
}
/**
* form style
*/
.site-form-wrapper {
  border: 2px solid #00384e;
  margin-bottom: 40px;
}
.site-form {
  padding-top: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.site-form-element-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.site-form__label {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: #82888f;
}
.site-form__input {
  display: block;
  width: 100%;
  height: 60px;
  border: 1px solid #adadad;
  border-radius: 3px;
  background-color: #f6f6f6;
  padding-left: 15px;
  padding-right: 15px;
  transition-duration: 0.15s;
  transition-property: background-color;
  transition-timing-function: ease-in;
}
.site-form__input:focus {
  outline: 0;
  border-color: #00384e;
  background-color: #fff;
}
.site-form__input:hover {
  background-color: #fff;
}
.site-form__textarea {
  display: block;
  width: 100%;
  min-height: 110px;
  background-color: #f6f6f6;
  border: 1px solid #adadad;
  border-radius: 3px;
  padding: 15px;
  resize: vertical;
  transition-duration: 0.15s;
  transition-property: background-color;
  transition-timing-function: ease-in;
}
.site-form__textarea:focus {
  border-color: #00384e;
  outline: 0;
  background-color: #fff;
}
.site-form__textarea:hover {
  background-color: #fff;
}
.site-form__input.error {
  border-color: #d04035;
  background-color: #f6dede;
}
.site-form__input.error:focus {
  outline: 0;
}
.site-form label.error {
  left: 10px;
  color: #d04035;
}
.content .site-form__button-submit,
.site-form__button-submit {
  padding-left: 105px;
  padding-right: 105px;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/**
* checkbox
*/
.modal-form-row--checkbox {
  position: relative;
  /* for label with error */
  display: flex;
  margin-bottom: 60px;
}
.modal-form-row--checkbox label.error {
  position: absolute;
  left: 0;
  top: 100%;
}
.modal-form__label-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  min-width: 21px;
  border-radius: 3px;
  border: 1px solid #adadad;
  background-color: #f6f6f6;
  padding: 3px;
  cursor: pointer;
  margin-right: 10px;
  transition: 0.15s border-color ease-in;
}
.modal-form__icon-checked {
  width: 20px;
  height: 20px;
  fill: #00384e;
  transition: 0.15s opacity ease-in;
  opacity: 0;
}
.modal-form__input-checkbox:checked ~ .modal-form__label-checkbox .modal-form__icon-checked {
  opacity: 1;
}
.modal-form__input-checkbox:focus ~ .modal-form__label-checkbox {
  border-color: #00384e;
}
.modal-form__input-checkbox.error ~ .modal-form__label-checkbox {
  border-color: #d04035;
  background-color: #f6dede;
}
.modal-form-checkbox-text {
  font-size: 12px;
  color: #818080;
  font-weight: 300;
}
.modal-form-checkbox-text a {
  font-size: 12px;
}
.modal-form-checkbox-text__link {
  color: #0082dd;
  text-decoration: underline;
}
.modal-form-checkbox-text__link:hover {
  color: #0082dd;
}
@media (max-width: 768px) {
  /* 768px по умолчанию */
  .content .site-form__button-submit,
  .site-form__button-submit {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.slider-simple-wrapper {
  position: relative;
  margin-bottom: 40px;
}
.slider-simple {
  width: 900px;
  height: 566px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.slider-simple-item img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.slider-simple__button-prev,
.slider-simple__button-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  border: 0;
  background-color: transparent;
  padding: 0;
  transform: translateY(-50%);
}
.slider-simple__button-prev:focus,
.slider-simple__button-next:focus {
  outline: 0;
}
.slider-simple__button-prev:hover .icon,
.slider-simple__button-next:hover .icon {
  fill: #00384e;
}
.slider-simple__button-prev .icon,
.slider-simple__button-next .icon {
  fill: #0c4733;
  transition: 0.2s fill ease;
}
.slider-simple__button-prev {
  left: 0;
}
.slider-simple__button-prev .icon {
  transform: rotate(90deg);
}
.slider-simple__button-next {
  right: 0;
}
.slider-simple__button-next .icon {
  transform: rotate(-90deg);
}
@media (max-width: 1024px) {
  .slider-simple {
    width: 710px;
    height: 447px;
  }
}
@media (max-width: 768px) {
  .slider-simple {
    width: auto;
    height: auto;
    max-height: 458px;
  }
}
/**
* modal form style
*
* Dependencies:
* 1. /src/components/wm.form/wm.form.less
* 2. /src/components/wm.buttons/wm.buttons.less
*/
.site-form-wrapper--modal {
  position: relative;
  background-color: #fff;
  max-width: 730px;
  margin: 15px auto;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.site-form-wrapper--modal .site-form__textarea {
  resize: none;
}
.modal-form-message-info-wrapper {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  background-color: #fff;
  padding-left: 3.5%;
  padding-right: 3.5%;
  text-align: center;
}
.modal-form-message-info-wrapper.disabled {
  z-index: -1;
  visibility: hidden;
}
.modal-form__message-info {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #222;
  margin-bottom: 40px;
}
.modal-form__message-info > div {
  margin-bottom: 12px;
}
.modal-form__message-info > div:last-child {
  margin-bottom: 0;
}
.modal-form__message-caption {
  color: #373737;
}
.nested-folder {
  margin-bottom: 40px;
}
.content .folder__head,
.folder__head {
  color: #00384e;
  margin-bottom: 5px;
}
.folder-list {
  background-color: #f6f6f6;
  padding: 15px 30px;
}
.folder-item:last-child .folder-item-filename-wrapper {
  margin-bottom: 0;
}
.folder-item:last-child .nested-folder-list {
  padding-bottom: 0;
}
.content .folder-item-filename-wrapper,
.folder-item-filename-wrapper {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  margin-bottom: 5px;
}
.content .folder-item-filename-wrapper:hover .icon,
.folder-item-filename-wrapper:hover .icon {
  fill: #0082dd;
}
.content .folder-item-filename-wrapper:hover .folder-item__filename,
.folder-item-filename-wrapper:hover .folder-item__filename {
  border-bottom-color: #0082dd;
}
.content .folder-item-filename-wrapper .icon,
.folder-item-filename-wrapper .icon {
  position: relative;
  top: 2px;
  display: block;
  width: 18px;
  height: 13px;
  fill: #0082dd;
  margin-left: 5px;
  transform: rotate(0);
  transform-origin: center;
  transition: 0.15s transform ease-in;
}
.content .folder-item-filename-wrapper.active,
.folder-item-filename-wrapper.active {
  color: #0082dd;
}
.content .folder-item-filename-wrapper.active .icon,
.folder-item-filename-wrapper.active .icon {
  fill: #0082dd;
  transform: rotate(-180deg);
}
.content .folder-item-filename-wrapper.active .folder-item__filename,
.folder-item-filename-wrapper.active .folder-item__filename {
  border-bottom-color: #0082dd;
}
.folder-item__filename {
  border-bottom: 1px dashed #0082dd;
}
.folder-item-icon-wrapper {
  align-self: flex-start;
}
.nested-folder-list {
  padding: 15px 30px;
  background-color: #f6f6f6;
}
.nested-folder-item {
  margin-bottom: 5px;
}
.nested-folder-item:last-child {
  margin-bottom: 0;
}
.nested-folder-link {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
  align-items: center;
}
.nested-folder-link:hover .icon {
  fill: #0082dd;
}
.nested-folder-link .icon {
  display: block;
  width: 30px;
  height: 30px;
  fill: #0082dd;
  margin-right: 10px;
}
.nested-folder-link-icon-wrapper {
  align-self: flex-start;
}
/* Подключаемые страницы */
/**
* Страница поиска
* Содержит в себе следующие компоненты:
* 1. Форма поиска
* 2. Список с результатами
* 3. Дополнительное меню с категориями
* -----------------------------------------------------------------------------
*/
.search-form {
  width: 100%;
  margin-bottom: 40px;
}
.search-form-input-wrapper {
  position: relative;
  display: inline;
}
.search-form__label {
  display: block;
  margin-bottom: 15px;
}
.search-form__input {
  display: inline-block;
  width: 100%;
  height: 66px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 57px;
  border-width: 3px;
  border-color: #e0e0e0;
  border-style: solid;
}
.search-form__input:focus {
  outline: 0;
}
.search-form__button-submit {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.search-form__button-submit .icon {
  width: 40px;
  height: 40px;
  padding: 7px;
  fill: #454545;
}
.search-result-list {
  margin-bottom: 40px;
}
.search-result-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;
}
.search-result-item:last-child {
  margin-bottom: 0;
}
.search-item-icon-wrapper {
  width: 45px;
  min-width: 45px;
  margin-right: 10px;
}
.search-item-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 12px;
}
.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.search-item-head {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;
}
.search-item-head:hover .search-item-head__text {
  color: #0082dd;
}
.search-item-head:visited .search-item-head__text {
  color: #0082dd;
}
.search-item-head__text {
  color: #0082dd;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.search-item-head__date {
  color: #666;
  padding-left: 5px;
  text-decoration: none;
}
.search-category-list {
  position: relative;
  top: -106px;
  margin-bottom: 40px;
}
.search-category-item {
  margin-bottom: 4px;
}
.search-category-item-link {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  width: 100%;
  height: 66px;
  background-color: #f4f4f4;
  padding-right: 15px;
  text-decoration: none;
  color: #111;
  font-weight: 600;
  transition: 0.15s background-color ease-in;
}
.search-category-item-link:hover {
  background-color: #00384e;
}
.search-category-item-link:hover .search-category-item-link__text {
  color: #fff;
}
.search-category-item-link:hover .search-category-item-link__count {
  color: #e0e0e0;
}
.search-category-item-link:hover svg {
  fill: #fff;
}
.search-category-item-link--result-all {
  opacity: 0.7;
  background-color: #00384e;
  transition: 0.15s opacity ease-in;
}
.search-category-item-link--result-all .search-category-item-link__text {
  color: #fff;
}
.search-category-item-link--result-all .search-category-item-link__count {
  color: #e0e0e0;
}
.search-category-item-link--result-all svg {
  fill: #fff;
}
.search-category-item-link--result-all:hover {
  opacity: 1;
}
.search-category-item-link-icon-wrapper {
  width: 45px;
  min-width: 45px;
}
.search-category-item-link-icon-wrapper + .search-category-item-link__text {
  padding-left: 3px;
}
.search-category-item-link-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-category-item-link-icon-inner-wrapper svg {
  width: 100%;
  height: auto;
  max-width: 21px;
  transition: 0.15s fill ease-in;
}
.search-category-item-link__text {
  transition: 0.15s color ease-in;
  padding-right: 5px;
  padding-left: 15px;
}
.search-category-item-link__count {
  transition: 0.15s color ease-in;
  color: #666;
}
.paginaion-search-wrapper .pagination-wrapper {
  text-align: left;
}
.search-item__caption .highlight {
  background-color: rgba(57, 181, 73, 0.42);
  transition: 0.15s color ease-in;
}
.search-item__caption .highlight-separate {
  font-weight: bold;
}
@media (max-width: 1024px) {
  .search-category-list {
    position: static;
  }
}
/* print styles */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:after,
  *:before {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }
}
